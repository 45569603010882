import HomePage from 'Pages/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import './App.scss';
import { useAuth } from 'Hooks/useAuth';
import SignIn from 'Pages/SignIn/Signin';
import Dashboard from 'Pages/Dashboard/Dashboard';
import SignUp from 'Pages/SignUp/SignUp';
import ConfirmSignUp from 'Pages/ConfirmSignUp/ConfirmSignUp';
import { LinearProgress } from '@mui/material';
import ForgotPassword from 'Pages/ForgotPassword/ForgotPassword';
import ConfirmForgotPassword from 'Pages/ConfirmForgotPassword/ConfirmForgotPassword';

const App = () => {
  const auth = useAuth();

  return auth.isLoading ? (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path='signin' element={<SignIn />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='confirmsignup' element={<ConfirmSignUp />} />
        <Route path='forgotpassword' element={<ForgotPassword />} />
        <Route path='confirmforgotpassword' element={<ConfirmForgotPassword />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='*' element={<p>Page Not Found</p>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
