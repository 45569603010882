import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { useAuth } from 'Hooks/useAuth';
import { useNavigate } from 'react-router-dom';

function Copyright(props: { sx: { mt: number } }) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://teapotchef.com/'>
        teapotchef.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ConfirmSignUp() {
  const { email, username, confirmSignUp, resendConfirmationCode } = useAuth();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [emailAddress, setEmailAddress] = useState(email);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await confirmSignUp(username, code);

    if (result.success) {
      navigate('/dashboard', { replace: true });
    } else {
      alert(result.message);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.dark' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Confirm sign up
        </Typography>
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='email'
                color='info'
                required
                fullWidth
                id='email'
                label='Email Address'
                value={emailAddress}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmailAddress(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                color='info'
                fullWidth
                id='code'
                label='Verification Code'
                name='code'
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCode(event.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            color='secondary'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Confirm
          </Button>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Button variant='text' color='info' onClick={() => resendConfirmationCode(username)}>
                Resend code
              </Button>
            </Grid>
            <Grid item>
              <Link href='/signin' color='secondary.contrastText' variant='body2'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
