// orange primary colors
export const PRIMARY_MAIN = '#ffa88a';
export const PRIMARY_LIGHT = '#FFD7C9';
export const PRIMARY_DARK = '#fc7f56';

// blue secondary colors
export const SECONDARY_MAIN = '#9ecfff';
export const SECONDARY_LIGHT = '#E3F1FF';
export const SECONDARY_DARK = '#47a3ff';

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const CREAM = '#F9F9F9';
