import { Box, LinearProgress } from '@mui/material';
import PrivateRoute from 'Components/Route/PrivateRoute';
import { useAuth } from 'Hooks/useAuth';
import ButtonAppBar from 'Layouts/ButtonAppBar/ButtonAppBar';

const Dashboard = () => {
  const { email, isLoading } = useAuth();

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color='secondary' />
      </Box>
    );
  }

  return (
    <PrivateRoute>
      <div>
        <ButtonAppBar />
        <div>Welcome {email}!!</div>
        <div>Login Succeed</div>
      </div>
    </PrivateRoute>
  );
};

export default Dashboard;
