import { Navigate } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';

type Props = {
  children?: React.ReactNode;
};

const PrivateRoute: React.FC<Props> = ({ children }: Props) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{children}</> : <Navigate to='/' />;
};

export default PrivateRoute;
