import './Home.scss';
import ButtonAppBar from 'Layouts/ButtonAppBar/ButtonAppBar';

const Home = () => {
  return (
    <div className='container'>
      <ButtonAppBar />
      <div className='content'>
        <div>Welcome to TeapotChef!</div>
      </div>
    </div>
  );
};

export default Home;
