import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from 'Hooks/useAuth';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  PRIMARY_MAIN,
  PRIMARY_LIGHT,
  PRIMARY_DARK,
  SECONDARY_MAIN,
  SECONDARY_LIGHT,
  SECONDARY_DARK,
} from 'Utils/color';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
      light: PRIMARY_LIGHT,
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY_MAIN,
      light: SECONDARY_LIGHT,
      dark: SECONDARY_DARK,
    },
  },
});

root.render(
  <React.StrictMode>
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ProvideAuth>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
