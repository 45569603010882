import { Amplify, Auth, Hub } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AwsCognitoConfig } from '../Config/auth';

Amplify.configure({ Auth: AwsCognitoConfig });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  email: string;
  signIn: (username: string, password: string) => Promise<Result>;
  signUp: (username: string, email: string, password: string) => Promise<Result>;
  confirmSignUp: (username: string, code: string) => Promise<Result>;
  resendConfirmationCode: (username: string) => Promise<Result>;
  forgotPassword: (username: string) => Promise<Result>;
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<Result>;
  signOut: () => void;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    listenToAutoSignInEvent();

    Auth.currentAuthenticatedUser()
      .then((result) => {
        setUsername(result.username);
        setEmail(result.attributes.email);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUsername('');
        setEmail('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        const user = payload.data;

        setUsername(user.username);
        setEmail(user.attributes.email);
        setIsAuthenticated(true);
        setIsLoading(false);
      } else if (event === 'autoSignIn_failure') {
        setUsername('');
        setEmail('');
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    });
  }

  const signIn = async (username: string, password: string) => {
    try {
      const result = await Auth.signIn(username, password);

      setUsername(result.username);
      setEmail(result.attributes.email);
      setIsAuthenticated(true);

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'LOGIN FAIL',
      };
    }
  };

  const signUp = async (username: string, email: string, password: string) => {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        autoSignIn: { enabled: true },
        attributes: {
          email,
        },
      });

      setUsername(user.getUsername());
      setEmail(email);

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'SIGNUP FAIL',
      };
    }
  };

  const confirmSignUp = async function confirmSignUp(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, code, { forceAliasCreation: false });

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'CONFIRM SIGNUP FAIL',
      };
    }
  };

  const resendConfirmationCode = async function resendConfirmationCode(username: string) {
    try {
      await Auth.resendSignUp(username);

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'RESEND CONFIRMATION CODE FAIL',
      };
    }
  };

  const forgotPassword = async function forgotPassword(username: string) {
    try {
      await Auth.forgotPassword(username);

      // assume User uses email address to reset password
      setEmail(username);

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'FORGOT PASSWORD REQUEST FAIL',
      };
    }
  };

  const forgotPasswordSubmit = async function forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string,
  ) {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'FORGOT PASSWORD SUBMIT FAIL',
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername('');
      setEmail('');
      setIsAuthenticated(false);

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    username,
    email,
    signIn,
    signUp,
    confirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    signOut,
    resendConfirmationCode,
  };
};

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
